/* eslint-disable @typescript-eslint/no-shadow */
import React, { useRef, useState, useEffect, useCallback } from 'react';
import classnames from 'classnames';
import T from 'i18n';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import FileDropZone from '../../common/FileDropZone';
import DocumentsModal from '../DocumentsModal';
import { documentModalActions, fileTypes } from '../enums';
import { diff } from 'deep-object-diff';

import TableCase from '../TableCase';
import history from 'common/history';
import { useDispatch, useSelector } from 'react-redux';
import { assignDocuments } from '../redux/assignDocuments';
import { unassignDocuments } from '../redux/unassignDocuments';
import { updateDocumentMeta } from '../redux/updateDocumentMeta';
import { updateDocument } from '../redux/updateDocument';
import { replaceFile } from '../redux/replaceFile';
import { generateHyperlinks } from '../redux/generateHyperlinks';
import FolderFooter from './FolderFooter';
import FolderBreadcrumb from './FolderBreadcrumb';
import equal from 'react-fast-compare';
import { openNewBackgroundTab } from 'utils/browser';
import { fetchFolderSpecificFileDetails } from '../redux/fetchFolderSpecificFileDetails';
import { uploadTranscriptNotes } from '../redux/uploadTranscriptNotes';
import {
  selectPermissions,
  selectDocumentCount,
  selectTotalPageCount,
  selectCurrentFolderId,
  selectTotalDocumentsCount,
  selectCurrentFolder,
  selectTotalDocumentSize,
  selectParams,
  selectIsGlobalPageLookup,
  selectCurrentCaseId,
  selectFetchDocumentsPending,
  selectUseBackEndForPresentMode,
  selectCurrentSelectedFileMetaData,
  selectDocFinderFilter,
  selectIsLocalUploadDrawerOpen,
} from 'common/selectors';
import { useFetchFileDetails } from '../redux/fetchFileDetails';
import SideDrawer from '../SideDrawer';
import UploadFileContent from './UploadFileContent';
import { setCurrentSelectedFile } from '../redux/setCurrentSelectedFile';
import { BatchUploadReport, FileMetaData } from './types';
import UploadStatusWindow from './UploadStatusWindow';
import { usePrevious } from 'utils/hooks';
import DocFinderFilters from './DocFinderFilters';
import { setLocalUploadDrawer } from 'features/globalUpload/redux/uploadDrawer';
import DataMapper from './DataMapper';

export default ({ category }: any) => {
  const [selectedRows, setSelectedRows] = useState<Array<any>>([]);
  const [showModal, setShowModal] = useState(false);
  const [uploadComplete, setUploadComplete] = useState(false);
  const [currentBatchId, setCurrentBatchId] = useState<number | null>(null);
  const [uploadInProgress, setUploadInProgress] = useState(false);
  const [batchSize, setBatchSize] = useState(0);
  const [fileMetadata, setFileMetadata] = useState<FileMetaData[]>([]);
  const [modalAction, setModalAction] = useState('null');
  const [filteredRowCount, setFilteredRowCount] = useState(null);
  const [filterValue, setFilterValue] = useState('');
  const [filterCategory, setFilterCategory] = useState(null);
  const [gridList, setGridList] = useState<any[]>([]);
  const [uploadInnerFile, setUploadInnerFile] = useState<boolean>(false);
  const [batchUploading, setBatchUploading] = useState<boolean>(false);
  const [batchUploadReport, setBatchUploadReport] = useState<BatchUploadReport | null>(null);

  const { file: alreadyOpenFileId, quickLink } = useSelector(selectParams) as any;
  const currentFolder = useSelector(selectCurrentFolder) as any;
  const permissions = useSelector(selectPermissions);
  const documentCount = useSelector(selectDocumentCount);
  const totalPageCount = useSelector(selectTotalPageCount);
  const currentFolderId = useSelector(selectCurrentFolderId);
  const prevFolderId = usePrevious(currentFolderId);
  const totalDocumentCount = useSelector(selectTotalDocumentsCount);
  const fetchDocumentsPending = useSelector(selectFetchDocumentsPending);
  const totalDocumentSize = useSelector(selectTotalDocumentSize);
  const useBackEndForPresentMode = useSelector(selectUseBackEndForPresentMode);
  const currentFileMetaData = useSelector(selectCurrentSelectedFileMetaData);
  const hasDocFinderFilter = useSelector(selectDocFinderFilter);

  const isTrialBooks = category === T.translate('case.trialbooks');
  const isAllDocuments = category === T.translate('case.allDocuments');
  const isQuickLinksPage = category === T.translate('case.quickLinks');
  const isTagsPage = quickLink === 'tags';
  const isPresentModePage =
    category === T.translate('case.presentMode') && !useBackEndForPresentMode;
  const isPresentModeWithBackEnd =
    category === T.translate('case.presentMode') && useBackEndForPresentMode;
  const isSearchModePage = category === T.translate('case.searchMode');
  const isAiToolsPage = category === T.translate('generic.aiTools');
  const isDocFinderPage = category === T.translate('case.docFinder');

  const showSidebar = useSelector(selectIsLocalUploadDrawerOpen);

  const { fetchFileDetails } = useFetchFileDetails();
  const dispatch = useDispatch();

  const dropzoneRef = useRef<any>();

  const handleCloseModal = () => setShowModal(false);

  const openDialog = () => {
    if (dropzoneRef.current) {
      if (uploadInnerFile) setUploadInnerFile(false);
      dropzoneRef.current.open();
    }
  };

  const openDialogCR = () => {
    if (dropzoneRef.current) {
      setUploadInnerFile(true);
      dropzoneRef.current.open();
    }
  };

  const getFileData = (data: any, folderSpecificData: any) => {
    const folderSpecificProp = ['name', 'tab', 'startPage'];
    return {
      ...Object.entries(data).reduce((previousItem, [key, value]) => {
        return folderSpecificData
          ? folderSpecificProp.includes(key)
            ? { ...previousItem, [key]: value }
            : previousItem
          : !folderSpecificProp.includes(key)
          ? { ...previousItem, [key]: value }
          : previousItem;
      }, {}),
    };
  };

  const getFolderSpecificCleanData = (data: any, file: any) => {
    const cleanData = {
      ...Object.entries(data).reduce(
        (a, [k, v]) =>
          (v === '' && file[k] === data[k]) || file[k] === data[k] ? a : { ...a, [k]: v },
        {},
      ),
    };
    return cleanData;
  };

  const handleSave = (
    folderId: any,
    action: any,
    documents: any,
    data: any,
    handleClose: any,
    setLoading: any,
  ) => {
    switch (action) {
      case documentModalActions.copy:
        dispatch(
          assignDocuments({
            documentIds: documents.map(
              ({ compositeKey }: { compositeKey: string }) => compositeKey,
            ),
            folderId: folderId,
          }),
        );
        break;
      case documentModalActions.remove:
        dispatch(unassignDocuments(documents));
        break;
      case documentModalActions.updateMeta:
        if (isAllDocuments) dispatch(updateDocumentMeta(data, documents[0].id));
        else {
          if (
            data.name !== documents[0].name ||
            data.tab !== documents[0].tab ||
            data.startPage !== documents[0].startPage
          ) {
            const folderSpecificData = getFileData(data, true);
            const cleanData = getFolderSpecificCleanData(folderSpecificData, documents[0]);
            Object.keys(cleanData).length > 0 &&
              dispatch(updateDocument(cleanData, documents[0].id));
          }
          const fileMetaData = getFileData(data, false);
          Object.keys(fileMetaData).length > 1 &&
            dispatch(updateDocumentMeta(fileMetaData, documents[0].id));
        }
        break;
      case documentModalActions.replace:
        dispatch(replaceFile(data, documents[0].id))
          .then(() => {
            setLoading(false);
            handleClose();
          })
          .catch(() => {
            setLoading(false);
          });
        break;
      case documentModalActions.uploadTranscriptNotes:
        dispatch(
          uploadTranscriptNotes(data.newFile[0], data.onBehalfOfUserId, documents[0].id),
        ).finally(() => {
          setLoading(false);
          handleClose();
        });
        break;
      case documentModalActions.generateHyperlinks:
        dispatch(generateHyperlinks({ ...data, useFileNames: true }, documents[0].id)).finally(
          () => {
            setLoading(false);
            handleClose();
          },
        );
        break;
      case documentModalActions.generateTranscriptsbasedHyperlinks:
        dispatch(generateHyperlinks({ ...data, isTranscript: true }, documents[0].id)).finally(
          () => {
            setLoading(false);
            handleClose();
          },
        );
        break;
      case documentModalActions.findAndReplaceHyperlinks:
        dispatch(
          generateHyperlinks(
            { ...data, autoGenHyperlinksFromPageRefs: !data.useAllExternalIDs },
            documents[0].id,
          ),
        ).finally(() => {
          setLoading(false);
          handleClose();
        });
        break;
      default:
    }
  };

  const onRowSelected = useCallback((selectedRowsNew: any) => {
    setSelectedRows(prevSelectedRows => {
      if (!equal(selectedRowsNew, prevSelectedRows)) {
        return selectedRowsNew;
      }
      return prevSelectedRows;
    });
  }, []);

  const onFilterChanged = (filteredRows: any, value: any) => {
    setFilteredRowCount(filteredRows && filteredRows.length);
    setFilterValue(value);
  };

  const onFilterCategoryChanged = (category: any) => {
    setFilterCategory(category);
  };

  const handleShowModal = useCallback(
    (modalAct: any) => {
      const currentUrlHashVal = window.location.hash;
      const hashFileId = currentUrlHashVal.split('#')[1];
      if (
        (hashFileId && selectedRows.length === 1 && selectedRows[0].id !== hashFileId) ||
        (hashFileId && selectedRows.length !== 1)
      )
        history.push(history.location.pathname, { ...history.location, hash: '' });

      setShowModal(true);
      setModalAction(modalAct);
    },
    [selectedRows],
  );

  const handleUserKeyPress = useCallback(
    (e: any) => {
      if (
        !(
          isQuickLinksPage ||
          isPresentModePage ||
          isPresentModeWithBackEnd ||
          isSearchModePage ||
          isDocFinderPage
        ) &&
        selectedRows.length === 1
      ) {
        if ((e.ctrlKey || e.metaKey) && e.code === 'KeyM') {
          if (alreadyOpenFileId && alreadyOpenFileId !== selectedRows[0].id) {
            history.push(
              history.location.pathname.replace(/\/files.*/, ``) + `/files/${selectedRows[0].id}`,
            );
          }
          handleShowModal(documentModalActions.updateMeta);
        } else if ((e.ctrlKey || e.metaKey) && e.code === 'Period')
          if (!selectedRows[0].private) {
            history.push(
              history.location.pathname.replace(/\/files.*/, ``) +
                `/files/${selectedRows[0].id}?present=public`,
            );
          }
      }
    },
    [
      isQuickLinksPage,
      isPresentModePage,
      isPresentModeWithBackEnd,
      isSearchModePage,
      isDocFinderPage,
      selectedRows,
      alreadyOpenFileId,
      handleShowModal,
    ],
  );

  const getFileDetails = useCallback(
    (fileId: string) => {
      if (fileId)
        fetchFileDetails({ fileId }).then((res: any) => {
          dispatch(setCurrentSelectedFile(res));
        });
    },
    [fetchFileDetails, dispatch],
  );

  useEffect(() => {
    window.addEventListener('keydown', handleUserKeyPress);

    return () => {
      window.removeEventListener('keydown', handleUserKeyPress);
    };
  }, [handleUserKeyPress]);

  useEffect(() => {
    //Provide document details to viewer when page is refreshed
    if (
      selectedRows.length === 1 &&
      alreadyOpenFileId === selectedRows[0].id &&
      Object.keys(currentFileMetaData).length === 0
    )
      dispatch(setCurrentSelectedFile(selectedRows[0]));
    else if (
      (isPresentModeWithBackEnd || isDocFinderPage || isSearchModePage) &&
      selectedRows.length === 0 &&
      alreadyOpenFileId &&
      Object.keys(currentFileMetaData).length === 0
    )
      getFileDetails(alreadyOpenFileId);
  }, [
    selectedRows,
    alreadyOpenFileId,
    dispatch,
    currentFileMetaData,
    getFileDetails,
    isPresentModeWithBackEnd,
    isDocFinderPage,
    isSearchModePage,
  ]);

  const docFinderFilter = useSelector(selectDocFinderFilter);

  const isPresenterTools =
    isQuickLinksPage ||
    isPresentModePage ||
    isPresentModeWithBackEnd ||
    isSearchModePage ||
    (!docFinderFilter && isDocFinderPage);
  const isIndexFolder = currentFolder && currentFolder.readOnly;
  const { folders: { canUploadDocuments = undefined } = {} } = permissions;

  const globalPaging = currentFolder && currentFolder.globalPaging;
  const isGlobalPageLookUp = useSelector(selectIsGlobalPageLookup);
  const caseId = useSelector(selectCurrentCaseId);

  const openViewerWithFileDetails = (bundleDetails: any, selectedItems: Array<any>) => {
    const currentFileMetaDataNew: Record<string, any> = {
      ...selectedItems[0],
      ...(bundleDetails && Object.keys(bundleDetails).length > 0 && bundleDetails),
    };
    const isGlobalPaging: boolean =
      globalPaging ||
      isGlobalPageLookUp ||
      (bundleDetails && Object.keys(bundleDetails).length > 0);
    const urlWithParams: string =
      selectedItems[0].type === fileTypes.mp4
        ? ''
        : isGlobalPaging && currentFileMetaDataNew
        ? `&tab=${currentFileMetaDataNew.tab}&startPage=${currentFileMetaDataNew.startPage}&pageCount=${currentFileMetaDataNew.pageCount}&globalPagePrefix=${currentFileMetaDataNew.globalPagePrefix}`
        : '';
    openNewBackgroundTab(
      `/case/${caseId}/files/${selectedItems[0].id}?folderId=${currentFolder.id ||
        'notAFolder'}${urlWithParams}`,
    );
  };

  const isTrialBundle = currentFolder && currentFolder.type === 'trialbook';

  const getFolderSpecificFileDetails = (selectedItems: any[]) => {
    if ((isTrialBundle && !globalPaging) || (!isTrialBundle && !isGlobalPageLookUp)) {
      fetchFileDetails({
        fileId: selectedItems[0]?.id,
      }).then((res: any) => {
        const currentFileFirstTrialBundleLocation =
          res.bundleLocations.find((bundle: any) => bundle.containerType === 'trialbook') || {};
        if (
          currentFileFirstTrialBundleLocation &&
          Object.keys(currentFileFirstTrialBundleLocation).length > 0
        ) {
          dispatch(
            fetchFolderSpecificFileDetails({
              fileId: selectedItems[0].id,
              firstTrialbookOnly: false,
            }),
          ).then((resp: any) => {
            const bundleDetails = resp.find(
              (item: any) => item.folderId === currentFileFirstTrialBundleLocation.folderId,
            );
            openViewerWithFileDetails(bundleDetails, selectedItems);
          });
        } else openViewerWithFileDetails(null, selectedItems);
      });
    } else openViewerWithFileDetails(null, selectedItems);
  };

  const [sortMode, setSortMode] = useState(false);

  useEffect(() => {
    if (currentFolderId !== prevFolderId) {
      setDrawerContent(null);
      clearAll();
    }
  }, [currentFolderId, prevFolderId]);

  const clearAll = () => {
    setDrawerContent(null);
    setUploadComplete(false);
    setFileMetadata([]);
    setCurrentBatchId(null);
    setUploadInProgress(false);
    setBatchUploadReport(null);
    setBatchSize(0);
  };

  const defaultFilters = {
    term: '',
    goToGlobalPageIdx: '',
    authors: [] as string[],
    recipients: [] as string[],
    dateFrom: null as Date | null,
    dateTo: null as Date | null,
    dateFilter: false,
    withAnnotations: false,
    withoutAnnotations: false,
    shared: false,
    notShared: false,
    public: false,
    private: false,
    withPublicHyperlinks: false,
    withoutPublicHyperlinks: false,
    trialBundles: [] as string[],
  };

  const [filter, setFilter] = useState(defaultFilters);

  const actualFilter = { ...diff(defaultFilters, filter) };

  const [drawerContent, setDrawerContent] = useState<'upload' | 'mapData' | null>(null);
  const openUploadDrawer = () => {
    dispatch(setLocalUploadDrawer(true, currentFolder));
    setDrawerContent('upload');
  };
  const openMapDataDrawer = () => {
    dispatch(setLocalUploadDrawer(true, currentFolder));
    setDrawerContent('mapData');
  };
  const handleOpenSidebar = () => {
    openUploadDrawer();
  };

  const handleCloseSidebar = () => {
    if (uploadComplete) setFileMetadata([]);
    setDrawerContent(null);
    dispatch(setLocalUploadDrawer(false, null));
  };

  const toggleSideBar = (toggle: boolean) => {
    if (toggle) openUploadDrawer();
    else handleCloseSidebar();
  };

  return (
    <div
      className={`case-table-wrapper ${drawerContent ? 'content-shift' : ''}`}
      key="table-wrapper"
    >
      <Container fluid className="h-100">
        <Row className="justify-content-between align-items-center p-2 pl-4">
          <FolderBreadcrumb
            filterCategory={filterCategory}
            selectedRows={selectedRows}
            openDialog={openDialog}
            handleShow={handleShowModal}
            isAllDocuments={isAllDocuments}
            isPresenterTools={isPresenterTools}
            category={category}
            getFolderSpecificFileDetails={getFolderSpecificFileDetails}
            openDialogCR={openDialogCR}
            sortMode={sortMode}
            handleSidebar={handleOpenSidebar}
            isDocFinderPage={docFinderFilter && isDocFinderPage}
            fileCount={documentCount}
            showSidebar={showSidebar}
            batchUploading={batchUploading}
            filter={filter}
            setFilter={setFilter}
            setShowMapData={openMapDataDrawer}
          />
        </Row>
        <Row className="secondRow">
          <div className="h-100" style={{ flexGrow: 1, marginLeft: '1rem', width: 'min-content' }}>
            <Card
              className={classnames({
                loading: fetchDocumentsPending,
              })}
            >
              {batchUploading || uploadComplete ? (
                <UploadStatusWindow
                  uploadComplete={uploadComplete}
                  currentBatchId={currentBatchId}
                  caseId={caseId}
                  uploadInProgress={batchUploading}
                  batchUploadReport={batchUploadReport}
                  clearAll={() => clearAll()}
                  batchSize={batchSize}
                />
              ) : (
                <FileDropZone
                  refLink={dropzoneRef}
                  folderId={currentFolderId}
                  showInfo
                  showProgress
                  noDrop={
                    !(canUploadDocuments && canUploadDocuments()) ||
                    isPresenterTools ||
                    isIndexFolder
                  }
                  uploadInnerFile={uploadInnerFile}
                  setUploadInnerFile={setUploadInnerFile}
                  showSidebar={showSidebar}
                >
                  <TableCase
                    onRowSelected={onRowSelected}
                    isTrialBooks={isTrialBooks}
                    isAllDocuments={isAllDocuments}
                    onFilterChanged={onFilterChanged}
                    onFilterCategoryChanged={onFilterCategoryChanged}
                    isQuickLinksPage={isQuickLinksPage}
                    isTagsPage={isTagsPage}
                    isPresentModePage={isPresentModePage}
                    isPresentModeWithBackEnd={isPresentModeWithBackEnd}
                    isSearchModePage={isSearchModePage}
                    isAiToolsPage={isAiToolsPage}
                    isDocFinderPage={isDocFinderPage}
                    setGridList={setGridList}
                    sortMode={sortMode}
                    setSortMode={setSortMode}
                    filter={filter}
                    setFilter={setFilter}
                    actualFilter={actualFilter}
                  />
                </FileDropZone>
              )}
            </Card>
            {!fetchDocumentsPending && (
              <FolderFooter
                {...(!isTagsPage && { totalPageCount, totalDocumentSize })}
                documentCount={documentCount}
                filteredRowCount={filteredRowCount}
                totalDocumentCount={totalDocumentCount}
                selectedRowsLength={selectedRows.length}
              />
            )}
          </div>
          {isDocFinderPage && hasDocFinderFilter && <DocFinderFilters />}
        </Row>
        <SideDrawer
          showSidebar={drawerContent !== null}
          onClose={handleCloseSidebar}
          title={
            drawerContent === 'mapData'
              ? T.translate('case.batchUpload.dataMapper.title')
              : drawerContent === 'upload'
              ? T.translate('case.batchUpload.title')
              : ''
          }
        >
          {drawerContent === 'mapData' && (
            <DataMapper
              setBatchId={setCurrentBatchId}
              setUploading={setBatchUploading}
              setUploadComplete={setUploadComplete}
              setBatchUploadReport={setBatchUploadReport}
              setBatchSize={setBatchSize}
            />
          )}
          {drawerContent === 'upload' && (
            <UploadFileContent
              fileMetadata={fileMetadata}
              setFileMetadata={setFileMetadata}
              currentFolderId={currentFolderId}
              showSidebar={drawerContent !== null}
              uploadComplete={uploadComplete}
              setUploadComplete={setUploadComplete}
              setBatchUploading={setBatchUploading}
              setCurrentBatchId={setCurrentBatchId}
              setUploadInProgress={setUploadInProgress}
              uploadInProgress={uploadInProgress}
              setBatchUploadReport={setBatchUploadReport}
              currentBatchId={currentBatchId}
              setShowSideBar={toggleSideBar}
              setBatchSize={setBatchSize}
            />
          )}
        </SideDrawer>
      </Container>
      {showModal && (
        <DocumentsModal
          handleClose={handleCloseModal}
          handleSave={handleSave}
          show={showModal}
          action={modalAction}
          selectedRows={selectedRows}
          filter={filterValue}
          isAllDocuments={isAllDocuments}
          currentGridList={gridList}
          fileCount={documentCount}
        />
      )}
    </div>
  );
};
