/* eslint-disable react-hooks/exhaustive-deps */
import './UploadFileContent.scss';

import React, { useEffect, useState, useCallback } from 'react';
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  Box,
  Button,
  SelectChangeEvent,
} from '@mui/material';
import { useDropzone } from 'react-dropzone';
import {
  createBatch,
  fetchBatchReport,
  parseCSV,
  parseTemplates,
  removeTrailingSpacesFromFilesWithAndWithoutExtensions,
  validateDateField,
} from './utils';
import { MappingProblem, ParsedTemplate } from './types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import api from 'common/api';
import T from 'i18n';
import { selectCurrentCaseId, selectCurrentFolderId, selectDocuments } from 'common/selectors';
import { useSelector } from 'react-redux';
import { Spinner } from 'features/common';
import { fileIdentifiers, PROBLEM_CATEGORIES } from './constants';
import MappingErrors from './MappingErrors';
import MappingPreview from './MappingPreview';

type DataMapperprops = {
  setBatchId: any;
  setUploading: any;
  setUploadComplete: any;
  setBatchUploadReport: any;
  setBatchSize: any;
};

const DataMapper = ({
  setBatchId,
  setUploading,
  setUploadComplete,
  setBatchUploadReport,
  setBatchSize,
}: DataMapperprops) => {
  const documents = useSelector(selectDocuments) as Array<any>;
  const currentFolderId = useSelector(selectCurrentFolderId) as string;
  const caseId = useSelector(selectCurrentCaseId) as string;

  const [mappingFile, setMappingFile] = useState<File | null>(null);
  const [parsedData, setParsedData] = useState<any[]>([]);
  const [columns, setColumns] = useState<string[]>([]);
  const [primaryKey, setPrimaryKey] = useState<string>('');
  const [columnMappings, setColumnMappings] = useState<{ [key: string]: string }>({});
  const [errors, setErrors] = useState<{ [key: string]: boolean }>({});
  const [failedRows, setFailedRows] = useState<any[]>([]);
  const [templates, setTemplates] = useState<ParsedTemplate[]>([]);
  const [templateError, setTemplateError] = useState<string>('');
  const [selectedTemplate, setSelectedTemplate] = useState<ParsedTemplate | null>(null);
  const [mappingData, setMappingData] = useState<any[]>([]);
  const [mappingInProgress, setMappingInProgress] = useState<boolean>(false);
  const [templatesFetched, setTemplatesFetched] = useState(false);
  const [apiError, setApiError] = useState<string | null>(null);
  const [previewOpen, setPreviewOpen] = useState(false);

  const errorRows = failedRows.filter(item => item.type === 'error');
  const warnings = failedRows.filter(item => item.type === 'warning');

  const handleTemplateChange = (event: SelectChangeEvent<string>) => {
    const selectedId = event.target.value as string;
    const template = templates.find(t => t?.id === selectedId) || null;
    setSelectedTemplate(template);
  };

  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    if (acceptedFiles.length === 0) return;

    const file = acceptedFiles[0];
    setMappingFile(file);
    parseCSVFile(file);
    // Fetch templates only once after primary key is selected
    if (!templatesFetched) {
      try {
        const response: any = await api.get(`/cases/${caseId}/batch-templates?type=map`);
        parseTemplates(response, setTemplates);
        setTemplatesFetched(true);
      } catch (error) {
        setTemplateError(T.translate('case.batchUpload.dataMapper.templateError'));
      }
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: ['.csv'],
    onDrop,
    multiple: false,
    disabled: mappingInProgress,
  });

  const parseCSVFile = (file: File) => {
    const reader = new FileReader();
    reader.onload = event => {
      const text = event.target?.result;
      if (typeof text === 'string') {
        const data = parseCSV(text);
        const dataWithRowNumbers = data.map((row, index) => ({
          ...row,
          __rowNum: index + 2, // Assuming header is row 1
        }));
        setParsedData(dataWithRowNumbers);
        setColumns(Object.keys(data[0]).filter(key => key !== '__rowNum'));
      }
    };

    reader.readAsText(file);
  };

  const handlePrimaryKeyChange = async (event: SelectChangeEvent<string>) => {
    const newPrimaryKey = event.target.value as string;
    setPrimaryKey(newPrimaryKey);

    if (errors.primaryKey) {
      setErrors(prev => ({ ...prev, primaryKey: false }));
    }
  };

  const handleMappingChange = (requiredField: string) => (event: SelectChangeEvent<string>) => {
    const selectedColumn = event.target.value as string;
    setColumnMappings(prev => ({
      ...prev,
      [requiredField]: selectedColumn,
    }));
    if (errors[requiredField]) {
      setErrors(prev => ({
        ...prev,
        [requiredField]: false,
      }));
    }
  };

  const handleValidation = () => {
    let hasError = false;
    const newErrors: { [key: string]: boolean } = {};

    selectedTemplate?.requiredFields.forEach(field => {
      if (!columnMappings[field]) {
        newErrors[field] = true;
        hasError = true;
      }
    });

    if (!primaryKey) {
      newErrors.primaryKey = true;
      hasError = true;
    }

    setErrors(newErrors);

    if (hasError) {
      setFailedRows([]);
      setMappingData([]);
      return;
    }

    const invalidRows: MappingProblem[] = [];

    // Build csvDataMap using the primary key from the CSV
    const csvDataMap: { [key: string]: any } = {};

    parsedData.forEach(row => {
      let primaryKeyValue = removeTrailingSpacesFromFilesWithAndWithoutExtensions(row[primaryKey]);
      if (primaryKeyValue) {
        const keyLower = primaryKeyValue;
        csvDataMap[keyLower] = row;
      } else {
        invalidRows.push({
          rowNumber: row.__rowNum,
          reason: T.translate('case.batchUpload.dataMapper.reasons.missingPrimaryKey', {
            row: row.__rowNum,
          }),
          type: 'error',
          problemCategory: PROBLEM_CATEGORIES.missingPrimaryKey,
        });
      }
    });

    const processedData: any[] = [];

    documents.forEach(doc => {
      let documentKey = selectedTemplate && doc[selectedTemplate.fileIdentifier];
      documentKey =
        selectedTemplate &&
        removeTrailingSpacesFromFilesWithAndWithoutExtensions(doc[selectedTemplate.fileIdentifier]);

      if (!documentKey) {
        invalidRows.push({
          rowNumber: null,
          reason: T.translate('case.batchUpload.dataMapper.reasons.missingFileIdentifier', {
            fileIdentifier: selectedTemplate?.fileIdentifier,
            fileName: doc.name,
          }),
          type: 'error',
          problemCategory: PROBLEM_CATEGORIES.missingFileIdentifier,
        });
        return;
      }
      const fileName = removeTrailingSpacesFromFilesWithAndWithoutExtensions(documentKey) as string;
      const dotIndex = fileName.lastIndexOf('.');
      const fileNameWithoutExt =
        dotIndex !== -1 ? fileName.substring(0, dotIndex).trim() : fileName.trim();
      let csvRow = csvDataMap[fileName] || csvDataMap[fileNameWithoutExt];

      if (!csvRow) {
        const possibleMatchKey = Object.keys(csvDataMap).find(key =>
          key.startsWith(fileNameWithoutExt),
        );
        if (possibleMatchKey) {
          csvRow = csvDataMap[possibleMatchKey];
        }
      }
      if (csvRow) {
        const mappedObject: any = {};
        selectedTemplate?.requiredFields?.forEach(field => {
          const columnName: any = columnMappings[field];
          const value = csvRow[columnName];
          mappedObject[field] = value;

          if (value === '' || value === undefined || value === null) {
            invalidRows.push({
              rowNumber: csvRow.__rowNum,
              reason: T.translate('case.batchUpload.dataMapper.reasons.missingRequiredField', {
                row: csvRow.__rowNum,
                columnName,
              }),
              type: 'warning',
              problemCategory: PROBLEM_CATEGORIES.missingRequiredField,
            });
          }
          validateDateField(
            field,
            value,
            selectedTemplate?.formatsForDates,
            csvRow,
            columnName,
            invalidRows,
          );
        });

        processedData.push(mappedObject);
      } else {
        invalidRows.push({
          rowNumber: null,
          reason: T.translate('case.batchUpload.dataMapper.reasons.notFoundInCSV', {
            fileName: documentKey,
          }),
          type: 'error',
          problemCategory: PROBLEM_CATEGORIES.notFoundInCSV,
        });
      }
    });

    setFailedRows(invalidRows);
    setBatchSize(processedData.length);
    setMappingData(processedData);
  };

  const triggerBatchProcessing = async (batchId: any) => {
    try {
      const response: any = await api.put(`/cases/${caseId}/batch/${batchId}`);
      if (!response || response.error) {
        throw new Error(T.translate('case.batchUpload.triggerBatchError'));
      }
    } catch (error) {
      throw new Error(T.translate('case.batchUpload.triggerBatchError'));
    }
  };

  const displayFileIdentifierHandler = (id: string) => {
    const translation = T.translate(fileIdentifiers?.get(id) as string);

    return T.translate('case.batchUpload.dataMapper.fileIdentifier', {
      fileIdentifier: translation,
    });
  };

  const handleMappingSubmit = async () => {
    setUploading(true);
    setMappingInProgress(true);
    setApiError(null);
    if (mappingData.length === 0) {
      console.warn('No valid data to submit.');
      return;
    }

    try {
      const jsonDataPayload = mappingData;

      const batchResponse: any = await createBatch(
        caseId,
        currentFolderId,
        jsonDataPayload,
        selectedTemplate?.id || '',
      );
      const batchId = batchResponse.id;

      await triggerBatchProcessing(batchId);
      await fetchBatchReport(caseId, batchId, setBatchUploadReport);

      clearMappingData();
      setBatchId(batchId);
      setUploadComplete(true);
      setUploading(false);
    } catch (error) {
      const detectedError: any = error;
      setMappingInProgress(false);
      setApiError(
        detectedError
          ? detectedError?.message
          : T.translate('case.batchUpload.dataMapper.apiErrorGeneral'),
      );
      setUploadComplete(true);
      setUploading(false);
    }
  };

  const clearMappingData = () => {
    setMappingFile(null);
    setColumnMappings({});
    setPrimaryKey('');
    setErrors({});
    setParsedData([]);
    setColumns([]);
    setFailedRows([]);
    setSelectedTemplate(null);
    setMappingData([]);
    setMappingInProgress(false);
    setApiError(null);
    setMappingInProgress(false);
  };

  useEffect(() => {
    if (
      parsedData.length > 0 &&
      selectedTemplate?.requiredFields &&
      selectedTemplate?.id &&
      primaryKey &&
      Object.keys(columnMappings).length === selectedTemplate?.requiredFields.length
    ) {
      handleValidation();
    } else {
      setFailedRows([]);
      setMappingData([]);
    }
  }, [parsedData, selectedTemplate, primaryKey, columnMappings, documents]);

  return (
    <div
      style={{
        padding: '20px',
      }}
    >
      <div
        {...getRootProps()}
        style={{
          border: `2px dashed ${isDragActive ? '#0078d4' : '#cccccc'}`,
          padding: '20px',
          textAlign: 'center',
          borderRadius: 6,
          cursor: 'pointer',
          marginBottom: '20px',
          backgroundColor: isDragActive ? '#f0f8ff' : 'transparent',
        }}
      >
        <input {...getInputProps()} />
        <p>{T.translate('case.batchUpload.dataMapper.uploadCSV')}</p>
      </div>
      {mappingFile ? (
        <p>
          {T.translate('case.batchUpload.dataMapper.mapHeadings.postMapFileAttached', {
            fileCount: documents?.length,
          })}
        </p>
      ) : (
        <p>
          {T.translate('case.batchUpload.dataMapper.mapHeadings.preMapFileAttached', {
            fileCount: documents?.length,
          })}
        </p>
      )}
      {mappingFile && (
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Typography variant="body1">{mappingFile.name}</Typography>
          <Button variant="text" color="secondary" onClick={clearMappingData}>
            {T.translate('case.batchUpload.dataMapper.reset')}
          </Button>
        </Box>
      )}

      {templates.length > 0 &&
        mappingFile &&
        (templatesFetched ? (
          <FormControl
            fullWidth
            variant="outlined"
            margin="normal"
            error={Boolean(templateError)}
            required
            disabled={mappingInProgress}
          >
            <InputLabel id="template-select-label">
              {T.translate('case.batchUpload.dataMapper.selectTemplate')}
            </InputLabel>
            <Select
              labelId="template-select-label"
              value={selectedTemplate?.id || ''}
              onChange={handleTemplateChange}
              label={T.translate('case.batchUpload.dataMapper.selectTemplate')}
            >
              {templates.map((template: any) => (
                <MenuItem key={template?.id} value={template?.id}>
                  {template?.name}
                </MenuItem>
              ))}
            </Select>
            {templateError && (
              <Box display="flex" alignItems="center" mt={1}>
                <Typography variant="caption" color="error">
                  {templateError}
                </Typography>
                <FontAwesomeIcon
                  icon={faExclamationCircle}
                  style={{
                    color: 'red',
                    marginLeft: '5px',
                  }}
                />
              </Box>
            )}
          </FormControl>
        ) : (
          <Spinner style={{ height: '1.5rem' }} />
        ))}

      {columns.length > 0 && selectedTemplate && (
        <FormControl
          fullWidth
          variant="outlined"
          margin="normal"
          error={Boolean(errors.primaryKey)}
          required
          disabled={mappingInProgress}
        >
          <Box display="flex" alignItems="center">
            <InputLabel id="primary-key-label">
              {T.translate('case.batchUpload.dataMapper.primaryKey')}
            </InputLabel>
          </Box>
          <Select
            labelId="primary-key-label"
            value={primaryKey}
            onChange={handlePrimaryKeyChange}
            label={T.translate('case.batchUpload.dataMapper.primaryKey')}
          >
            {columns.map(col => (
              <MenuItem key={col} value={col}>
                {col}
              </MenuItem>
            ))}
          </Select>
          <p className="under-label">
            {displayFileIdentifierHandler(selectedTemplate?.fileIdentifier || '')}
          </p>
          {errors.primaryKey && (
            <Box display="flex" alignItems="center" mt={1}>
              <Typography variant="caption" color="error">
                {T.translate('case.batchUpload.dataMapper.primaryKeyRequired')}
              </Typography>
              <FontAwesomeIcon
                icon={faExclamationCircle}
                style={{
                  color: 'red',
                  marginLeft: '5px',
                }}
              />
            </Box>
          )}
        </FormControl>
      )}
      {columns.length > 0 && (selectedTemplate?.requiredFields?.length as any) > 0 && (
        <Box display="flex" flexDirection="column" gap={3} mt={2}>
          {selectedTemplate?.requiredFields.map((field: any) => (
            <FormControl
              key={field}
              fullWidth
              variant="outlined"
              margin="normal"
              error={Boolean(errors[field])}
              required
              disabled={mappingInProgress}
            >
              <InputLabel id={`${field}-mapping-label`}>{field}</InputLabel>
              <Select
                labelId={`${field}-mapping-label`}
                value={columnMappings[field] || ''}
                onChange={handleMappingChange(field)}
                label={field}
              >
                {columns
                  .filter(
                    col =>
                      !Object.values(columnMappings).includes(col) || columnMappings[field] === col,
                  )
                  .map(col => (
                    <MenuItem key={col} value={col}>
                      {col}
                    </MenuItem>
                  ))}
              </Select>
              {field.toLowerCase().includes('date') &&
                selectedTemplate?.formatsForDates?.length > 0 && (
                  <Typography variant="caption" color="textSecondary" mt={1}>
                    {`Format: ${selectedTemplate?.formatsForDates?.join(', ')}`}
                  </Typography>
                )}
              {errors[field] && (
                <Box display="flex" alignItems="center" mt={1}>
                  <Typography variant="caption" color="error">
                    {T.translate('case.batchUpload.dataMapper.fieldRequired')}
                  </Typography>
                  <FontAwesomeIcon
                    icon={faExclamationCircle}
                    style={{
                      color: 'red',
                      marginLeft: '5px',
                    }}
                  />
                </Box>
              )}
            </FormControl>
          ))}
        </Box>
      )}

      <MappingErrors
        failedRows={failedRows}
        exclusionMessage={
          errorRows?.length ? T.translate('case.batchUpload.advancedSettings.rowsExcluded') : ''
        }
        normalMessage={
          warnings?.length ? T.translate('case.batchUpload.advancedSettings.fieldsNotMapped') : ''
        }
        mappingData={mappingData}
      />
      {apiError && (
        <Box mt={2}>
          <Typography variant="body1" color="error">
            {apiError}
          </Typography>
        </Box>
      )}
      {mappingFile && (
        <Box display="flex" justifyContent="space-between" mt={3}>
          {mappingFile && (
            <Button
              variant="outlined"
              color="primary"
              onClick={() => setPreviewOpen(true)}
              disabled={
                !selectedTemplate ||
                Object.keys(columnMappings).length < selectedTemplate.requiredFields.length ||
                mappingData.length === 0
              }
            >
              {T.translate('case.batchUpload.dataMapper.preview.previewButton')}
            </Button>
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={handleMappingSubmit}
            disabled={
              !mappingFile ||
              !selectedTemplate ||
              Object.keys(columnMappings).length < selectedTemplate.requiredFields.length ||
              mappingData.length === 0
            }
          >
            {mappingInProgress ? (
              <Spinner style={{ height: '1.5rem' }} />
            ) : (
              T.translate('case.batchUpload.dataMapper.submit')
            )}
          </Button>
        </Box>
      )}
      <MappingPreview
        open={previewOpen}
        onClose={() => setPreviewOpen(false)}
        mappingData={mappingData}
        fileIdentifier={selectedTemplate?.fileIdentifier}
      />
    </div>
  );
};

export default DataMapper;
