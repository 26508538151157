import React, { useEffect, useMemo, useRef, useState } from 'react';
import T from 'i18n';
import Grid from '@mui/material/Unstable_Grid2';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { FiltersStyles } from './FiltersStyles';
import classnames from 'classnames';
import { useFetchTags } from 'features/case/redux/fetchTags';
import isEqual from 'react-fast-compare';
import BookmarkButtonInput from './BookmarkButtonInput';
import ThreeStateToggles from './ThreeStateToggles';
import Bookmarks from './Bookmarks';
import DatePickers from './DatePickers';
import FilterOption from './FilterOption';
import { useSetFilterOptions } from 'features/case/redux/setFilterOptions';
import { selectFolderContainers } from 'common/selectors';
import { useSelector } from 'react-redux';
import WorkerFactory from './WorkerFactory';
import myWorker from './worker';

export default ({
  filterHandler,
  defaultFilters,
  documents,
  columns,
  isAnnotation,
}: {
  filterHandler: any;
  defaultFilters: any;
  documents: any;
  columns: any;
  isAnnotation: boolean;
}) => {
  const classes = FiltersStyles();

  const [filterCategory, setFilterCategory] = useState(defaultFilters);
  const { fetchTags } = useFetchTags();
  const [loading, setLoading] = useState<null | boolean>(null);

  const defaultFilterOptions = [
    columns.includes('author') && {
      filterName: T.translate('filters.authors'),
      filterKey: 'authors',
      // originalArray: authors,
    },
    columns.includes('recipient') && {
      filterName: T.translate('filters.recipients'),
      filterKey: 'recipients',
      // originalArray: recipients,
    },
    columns.includes('createdBy') && {
      filterName: T.translate('filters.createdBy'),
      filterKey: 'createdBy',
      valKey: 'id',
      labelKey: 'name',
      // originalArray: createdBy,
    },
    columns.includes('tags') && {
      filterName: T.translate('filters.tags'),
      filterKey: 'tags',
      valKey: 'id',
      labelKey: 'name',
      // originalArray: tags,
    },
    documents?.length > 0 &&
      documents[0].bundleLocations && {
        filterName: T.translate('filters.trialBundlesList'),
        filterKey: 'trialBundles',
        valKey: 'id',
        labelKey: 'name',
        // originalArray: trialBundles,
      },
  ].filter(Boolean);

  const { filterOptions, setFilterOptions } = useSetFilterOptions();

  const folderContainers = useSelector(selectFolderContainers);

  const trialBundlesMemo = useMemo(
    () => folderContainers.find((itm: { id: string }) => itm.id === 'trialbooks')?.folders || [],
    [folderContainers],
  );

  const options = filterOptions || defaultFilterOptions;

  const setFilters = (newFilterCategory: any) => {
    setFilterCategory(newFilterCategory);
    filterHandler(newFilterCategory);
  };

  const clearFilter = {
    authors: [],
    createdBy: [],
    dateFilter: false,
    dateFrom: null,
    dateTo: null,
    goToGlobalPageIdx: defaultFilters.goToGlobalPageIdx,
    private: false,
    public: false,
    recipients: [],
    tags: [],
    trialBundles: [],
    withAnnotations: false,
    withPublicHyperlinks: false,
    withoutAnnotations: false,
    withoutPublicHyperlinks: false,
    shared: false,
    notShared: false,
    ...options.reduce((acc: any, fo: any) => ({ ...acc, [fo.filterKey]: [] }), {}),
  };

  const clearFilters = () => {
    setFilters(clearFilter);
  };
  const [worker, setWorker] = useState<any>(null);

  useEffect(() => {
    const workerInstance = new WorkerFactory(myWorker) as any;

    // Set up event listener for messages from the worker
    workerInstance.onmessage = (event: any) => {
      setFilterOptions(event.data);
      setLoading(false);
    };

    // Save the worker instance to state
    setWorker(workerInstance);

    // Clean up the worker when the component unmounts
    return () => {
      workerInstance.terminate();
    };
  }, []); // Run this effect only once when the component mounts

  useEffect(() => {
    if (worker) {
      setLoading(true);
      fetchTags().then((tagsFetched: any) => {
        setLoading(true);
        worker.postMessage({
          documents,
          filterOptions,
          trialBundlesMemo,
          tagsFetched,
          defaultFilterOptions,
        });
      });
    }
  }, [documents, columns, worker]);

  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <div className={classes.paper} ref={containerRef}>
      <div className={classes.listSubheaderHeading}>
        {T.translate('filters.filters')}
        <div style={{ display: 'flex' }}>
          {!isEqual(filterCategory, clearFilter) && (
            <BookmarkButtonInput isAnnotation={isAnnotation} filterCategory={filterCategory} />
          )}
          <div style={{ margin: '0.5rem' }} />
          <Button
            variant="contained"
            size="small"
            color="primary"
            className={classnames(classes.clearButton)}
            onClick={clearFilters}
          >
            {T.translate('filters.clear')}
          </Button>
        </div>
      </div>
      <Divider />
      <PerfectScrollbar style={{ maxHeight: '75vh', padding: '0 1rem' }}>
        <Grid container spacing={2} style={{ width: '100%', margin: 0 }}>
          {columns && columns.includes('docDate') && (
            <DatePickers filterCategory={filterCategory} setFilters={setFilters} />
          )}
          {options.map((option: any, idx: number) => (
            <FilterOption
              key={option.filterName}
              idx={idx}
              originalArray={option.originalArray}
              filterName={option.filterName}
              filterKey={option.filterKey}
              valKey={option.valKey}
              labelKey={option.labelKey}
              setFilters={setFilters}
              filterCategory={filterCategory}
              loading={loading}
            />
          ))}
          {/* <Divider style={{ width: '100%' }} /> */}
          <ThreeStateToggles
            isAnnotation={isAnnotation}
            filterCategory={filterCategory}
            setFilters={setFilters}
          />
        </Grid>
      </PerfectScrollbar>
      <Bookmarks isAnnotation={isAnnotation} setFilters={setFilters} containerRef={containerRef} />
    </div>
  );
};
