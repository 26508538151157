/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useMemo } from 'react';
import { Typography, Paper, Tabs, Tab, Box } from '@mui/material';
import T from 'i18n';
import { MappingProblem } from './types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

import { PROBLEM_CATEGORIES_LABELS } from './constants';

type MappingErrorsProps = {
  failedRows: MappingProblem[];
  exclusionMessage: string;
  normalMessage: string;
  mappingData: any[];
};

const BADGE_HEIGHT = 16;

const MappingErrors: React.FC<MappingErrorsProps> = ({
  failedRows,
  exclusionMessage,
  normalMessage,
}) => {
  const [currentTab, setCurrentTab] = useState(0);
  const [expandedGroups, setExpandedGroups] = useState<{ [key: string]: boolean }>({});
  const errors = failedRows.filter(item => item.type === 'error');
  const warnings = failedRows.filter(item => item.type === 'warning');

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };
  const iconColor = currentTab === 0 ? 'red' : 'orange';

  const activeRows = currentTab === 0 ? errors : warnings;
  const activeMessage = currentTab === 0 ? exclusionMessage : normalMessage;

  const getGroupLabel = (problemCategory: keyof typeof PROBLEM_CATEGORIES_LABELS) => {
    return PROBLEM_CATEGORIES_LABELS[problemCategory];
  };

  const groupedData = useMemo(() => {
    const groups: { [key: string]: MappingProblem[] } = {};
    activeRows.forEach((item: any) => {
      const groupName = getGroupLabel(item?.problemCategory);
      if (!groups[groupName]) {
        groups[groupName] = [];
      }
      groups[groupName].push(item);
    });
    return groups;
  }, [activeRows]);
  if (failedRows.length === 0) return null;

  const toggleGroup = (groupName: string) => {
    setExpandedGroups(prev => ({
      ...prev,
      [groupName]: !prev[groupName],
    }));
  };

  return (
    <Paper
      elevation={0}
      style={{
        padding: '10px',
        marginTop: '10px',
        position: 'relative',
      }}
    >
      <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: '10px' }}>
        <Tabs
          value={currentTab}
          onChange={handleChange}
          sx={{
            '& .MuiTabs-indicator': {
              backgroundColor: '#748CEC',
            },
            '& .MuiTab-root.Mui-selected .MuiTypography-root': {
              color: '#748CEC',
            },
          }}
        >
          <Tab
            label={
              <Box display="flex" alignItems="center" gap={1}>
                <Typography variant="body2">{T.translate('case.batchUpload.errors')}</Typography>
                <Box
                  sx={{
                    backgroundColor: 'grey',
                    color: 'white',
                    borderRadius: '50%',
                    width: BADGE_HEIGHT,
                    height: BADGE_HEIGHT,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: errors.length > 99 ? '0.6rem' : '0.75rem',
                  }}
                >
                  {errors.length}
                </Box>
              </Box>
            }
            value={0}
            sx={{
              '& .MuiTabs-indicator': {
                backgroundColor: '#748CEC',
              },
              '& .MuiTab-root.Mui-selected .MuiTypography-root': {
                color: '#748CEC',
              },
            }}
          />
          <Tab
            label={
              <Box display="flex" alignItems="center" gap={1}>
                <Typography variant="body2">{T.translate('case.batchUpload.warnings')}</Typography>
                <Box
                  sx={{
                    backgroundColor: 'grey',
                    color: 'white',
                    borderRadius: '50%',
                    width: BADGE_HEIGHT,
                    height: BADGE_HEIGHT,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: warnings.length > 99 ? '0.6rem' : '0.75rem',
                  }}
                >
                  {warnings.length}
                </Box>
              </Box>
            }
            value={1}
            sx={{
              '& .MuiTabs-indicator': {
                backgroundColor: '#748CEC',
              },
              '& .MuiTab-root.Mui-selected .MuiTypography-root': {
                color: '#748CEC',
              },
            }}
          />
        </Tabs>
      </Box>

      <Typography variant="body1" color={'textSecondary'} style={{ marginBottom: '10px' }}>
        {activeMessage}
      </Typography>

      <ul style={{ listStyleType: 'none', paddingLeft: 0 }}>
        {Object.entries(groupedData).map(([groupName, items]) => (
          <li key={groupName} style={{ marginBottom: '10px' }}>
            <Box display="flex" alignItems="center" gap={1}>
              <FontAwesomeIcon
                icon={faTimesCircle}
                style={{
                  color: iconColor,
                  marginRight: '3px',
                }}
              />
              <Typography
                variant="subtitle1"
                onClick={() => toggleGroup(groupName)}
                sx={{
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  color: '#748CEC',
                }}
              >
                {`${groupName} (${items.length})`}
              </Typography>
            </Box>
            {/* Show individual items if the group is expanded */}
            {expandedGroups[groupName] && (
              <ul style={{ listStyleType: 'none', paddingLeft: '20px', marginTop: '5px' }}>
                {items.map((item, idx) => (
                  <li key={idx} style={{ marginBottom: '5px' }}>
                    {item.type === 'warning' ? (
                      <span>
                        <FontAwesomeIcon
                          icon={faTimesCircle}
                          style={{
                            color: 'orange',
                            marginRight: '3px',
                          }}
                        />
                        <strong>
                          {T.translate('case.batchUpload.dataMapper.row', { row: item.rowNumber })}
                        </strong>
                        {' - '}
                        {item.reason}
                      </span>
                    ) : (
                      <span>
                        <FontAwesomeIcon
                          icon={faTimesCircle}
                          style={{
                            color: 'red',
                            marginRight: '3px',
                          }}
                        />
                        <strong>{item.reason}</strong>
                      </span>
                    )}
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul>
    </Paper>
  );
};

export default MappingErrors;
