export const validFileExts = [
  'pdf',
  'doc',
  'dot',
  'docx',
  'docm',
  'dotx',
  'dotm',
  'rtf',
  'xls',
  'xlt',
  'xlsx',
  'xlsm',
  'xltx',
  'xltm',
  'ppt',
  'pot',
  'pps',
  'pptx',
  'pptm',
  'potx',
  'potm',
  'ppsx',
  'ppsm',
  'odt',
  'ott',
  'fodt',
  'ods',
  'ots',
  'fods',
  'odp',
  'otp',
  'fodp',
  'odf',
  'odg',
  'otg',
  'fodg',
  'dwg',
  'dxf',
  'dwf',
  'dgn',
  'html',
  'htm',
  'xhtml',
  'xhtm',
  'msg',
  'eml',
  'txt',
  'csv',
  'dcm',
  'dicom',
  'dcim',
  'dicm',
  'tif',
  'tiff',
  'jpg',
  'jpeg',
  'jp2',
  'jpc',
  'gif',
  'png',
  'psd',
  'psb',
  'bmp',
  'dib',
  'pct',
  'pic',
  'pict',
  'wmf',
  'emf',
  'pcx',
  'dcx',
  'ras',
  'pcd',
  'tga',
  'tpic',
  'cal',
  'cals',
  'ico',
  'cur',
  'ncr',
  'xwd',
  'sgi',
  'wbmp',
  'sct',
  'wpg',
  'xbm',
  'pbm',
  'pgm',
  'ppm',
  'img',
  'cut',
  'mp4',
];

export const validFileExtsShareOnly = ['lp', 'do', 'zip', 'xer', 'plf', 'inc', 'mpp'];

export const fileIdentifiers = new Map([
  ['tab', 'case.tab'],
  ['id', 'case.documentId'],
  ['name', 'case.name'],
  ['description', 'case.description'],
  ['globalPages', 'case.globalPages'],
  ['externalId', 'case.externalId'],
  ['discoveryId', 'case.discoveryId'],
  ['author', 'case.author'],
  ['recipient', 'case.recipient'],
  ['viewed', 'case.viewed'],
  ['dateViewed', 'case.dateViewed'], // Note: Conditional translation to 'case.datePresented' may apply
  ['docDate', 'case.docDate'],
  ['altDate', 'case.alternativeDate'],
  ['type', 'case.type'],
  ['pageCount', 'case.pageCount'],
  ['size', 'case.size'],
  ['info', 'case.info'],
]);

const mockMappingData: any[] = [
  {
    fileName: 'Batch Report New Day.txt',
    externalId: 'EXT_0001',
    date: '2024-01-01',
    lineBreakRemoved: true,
  },
  {
    fileName: 'SOC_00002.pdf',
    externalId: 'EXT_0002',
    date: '2024-01-02',
    lineBreakRemoved: false,
  },
  {
    fileName: 'SOC_00003.pdf',
    externalId: 'EXT_0003',
    date: '2024-01-03',
    lineBreakRemoved: false,
  },
  {
    fileName: 'SOC_00004.pdf',
    externalId: 'EXT_0004',
    date: '2024-01-04',
    lineBreakRemoved: false,
  },
  {
    fileName: 'SOC_00005.pdf',
    externalId: 'EXT_0005',
    date: '2024-01-05',
    lineBreakRemoved: false,
  },
  {
    fileName: 'SOC_00006.pdf',
    externalId: 'EXT_0006',
    date: '2024-01-06',
    lineBreakRemoved: false,
  },
  {
    fileName: 'SOC_00007.pdf',
    externalId: 'EXT_0007',
    date: '2024-01-07',
    lineBreakRemoved: false,
  },
  {
    fileName: 'SOC_00008.pdf',
    externalId: 'EXT_0008',
    date: '2024-01-08',
    lineBreakRemoved: false,
  },
  {
    fileName: 'SOC_00009.pdf',
    externalId: 'EXT_0009',
    date: '2024-01-09',
    lineBreakRemoved: false,
  },
  {
    fileName: 'SOC_00010.pdf',
    externalId: 'EXT_0010',
    date: '2024-01-10',
    lineBreakRemoved: false,
  },
  {
    fileName: 'SOC_00011.pdf',
    externalId: 'EXT_0011',
    date: '2024-01-11',
    lineBreakRemoved: false,
  },
  {
    fileName: 'SOC_00012.pdf',
    externalId: 'EXT_0012',
    date: '2024-01-12',
    lineBreakRemoved: false,
  },
  {
    fileName: 'SOC_00013.pdf',
    externalId: 'EXT_0013',
    date: '2024-01-13',
    lineBreakRemoved: false,
  },
  {
    fileName: 'SOC_00014.pdf',
    externalId: 'EXT_0014',
    date: '2024-01-14',
    lineBreakRemoved: false,
  },
  {
    fileName: 'SOC_00015.pdf',
    externalId: 'EXT_0015',
    date: '2024-01-15',
    lineBreakRemoved: false,
  },
  {
    fileName: 'SOC_00016.pdf',
    externalId: 'EXT_0016',
    date: '2024-01-16',
    lineBreakRemoved: false,
  },
  {
    fileName: 'SOC_00017.pdf',
    externalId: 'EXT_0017',
    date: '2024-01-17',
    lineBreakRemoved: false,
  },
  {
    fileName: 'SOC_00018.pdf',
    externalId: 'EXT_0018',
    date: '2024-01-18',
    lineBreakRemoved: false,
  },
  {
    fileName: 'SOC_00019.pdf',
    externalId: 'EXT_0019',
    date: '2024-01-19',
    lineBreakRemoved: false,
  },
  {
    fileName: 'SOC_00020.pdf',
    externalId: 'EXT_0020',
    date: '2024-01-20',
    lineBreakRemoved: false,
  },
  {
    fileName: 'SOC_00021.pdf',
    externalId: 'EXT_0021',
    date: '2024-01-21',
    lineBreakRemoved: false,
  },
  {
    fileName: 'SOC_00022.pdf',
    externalId: 'EXT_0022',
    date: '2024-01-22',
    lineBreakRemoved: false,
  },
  {
    fileName: 'SOC_00023.pdf',
    externalId: 'EXT_0023',
    date: '2024-01-23',
    lineBreakRemoved: false,
  },
  {
    fileName: 'SOC_00024.pdf',
    externalId: 'EXT_0024',
    date: '2024-01-24',
    lineBreakRemoved: false,
  },
  {
    fileName: 'SOC_00025.pdf',
    externalId: 'EXT_0025',
    date: '2024-01-25',
    lineBreakRemoved: false,
  },
  {
    fileName: 'SOC_00026.pdf',
    externalId: 'EXT_0026',
    date: '2024-01-26',
    lineBreakRemoved: false,
  },
  {
    fileName: 'SOC_00027.pdf',
    externalId: 'EXT_0027',
    date: '2024-01-27',
    lineBreakRemoved: false,
  },
  {
    fileName: 'SOC_00028.pdf',
    externalId: 'EXT_0028',
    date: '2024-01-28',
    lineBreakRemoved: false,
  },
  {
    fileName: 'SOC_00029.pdf',
    externalId: 'EXT_0029',
    date: '2024-01-29',
    lineBreakRemoved: false,
  },
  {
    fileName: 'SOC_00030.pdf',
    externalId: 'EXT_0030',
    date: '2024-01-30',
    lineBreakRemoved: false,
  },
  {
    fileName: 'SOC_00031.pdf',
    externalId: 'EXT_0031',
    date: '2024-01-31',
    lineBreakRemoved: false,
  },
  {
    fileName: 'SOC_00032.pdf',
    externalId: 'EXT_0032',
    date: '2024-02-01',
    lineBreakRemoved: false,
  },
  {
    fileName: 'SOC_00033.pdf',
    externalId: 'EXT_0033',
    date: '2024-02-02',
    lineBreakRemoved: false,
  },
  {
    fileName: 'SOC_00034.pdf',
    externalId: 'EXT_0034',
    date: '2024-02-03',
    lineBreakRemoved: false,
  },
  {
    fileName: 'SOC_00035.pdf',
    externalId: 'EXT_0035',
    date: '2024-02-04',
    lineBreakRemoved: false,
  },
  {
    fileName: 'SOC_00036.pdf',
    externalId: 'EXT_0036',
    date: '2024-02-05',
    lineBreakRemoved: false,
  },
  {
    fileName: 'SOC_00037.pdf',
    externalId: 'EXT_0037',
    date: '2024-02-06',
    lineBreakRemoved: false,
  },
  {
    fileName: 'SOC_00038.pdf',
    externalId: 'EXT_0038',
    date: '2024-02-07',
    lineBreakRemoved: false,
  },
  {
    fileName: 'SOC_00039.pdf',
    externalId: 'EXT_0039',
    date: '2024-02-08',
    lineBreakRemoved: false,
  },
  {
    fileName: 'SOC_00040.pdf',
    externalId: 'EXT_0040',
    date: '2024-02-09',
    lineBreakRemoved: false,
  },
  {
    fileName: 'SOC_00041.pdf',
    externalId: 'EXT_0041',
    date: '2024-02-10',
    lineBreakRemoved: false,
  },
  {
    fileName: 'SOC_00042.pdf',
    externalId: 'EXT_0042',
    date: '2024-02-11',
    lineBreakRemoved: false,
  },
  {
    fileName: 'SOC_00043.pdf',
    externalId: 'EXT_0043',
    date: '2024-02-12',
    lineBreakRemoved: false,
  },
  {
    fileName: 'SOC_00044.pdf',
    externalId: 'EXT_0044',
    date: '2024-02-13',
    lineBreakRemoved: false,
  },
  {
    fileName: 'SOC_00045.pdf',
    externalId: 'EXT_0045',
    date: '2024-02-14',
    lineBreakRemoved: false,
  },
  {
    fileName: 'SOC_00046.pdf',
    externalId: 'EXT_0046',
    date: '2024-02-15',
    lineBreakRemoved: false,
  },
  {
    fileName: 'SOC_00047.pdf',
    externalId: 'EXT_0047',
    date: '2024-02-16',
    lineBreakRemoved: false,
  },
  {
    fileName: 'SOC_00048.pdf',
    externalId: 'EXT_0048',
    date: '2024-02-17',
    lineBreakRemoved: false,
  },
  {
    fileName: 'SOC_00049.pdf',
    externalId: 'EXT_0049',
    date: '2024-02-18',
    lineBreakRemoved: false,
  },
  {
    fileName: 'SOC_00050.pdf',
    externalId: 'EXT_0050',
    date: '2024-02-19',
    lineBreakRemoved: false,
  },
];

export default mockMappingData;

export const PROBLEM_CATEGORIES = {
  missingPrimaryKey: 'missingPrimaryKey',
  missingFileIdentifier: 'missingFileIdentifier',
  missingRequiredField: 'missingRequiredField',
  notFoundInCSV: 'notFoundInCSV',
  invalidDate: 'invalidDate',
};
// TODO: Translate categories
export const PROBLEM_CATEGORIES_LABELS = {
  missingPrimaryKey: 'Primary Key Missing',
  missingFileIdentifier: 'File Identifier Missing',
  missingRequiredField: 'Required Field Missing',
  notFoundInCSV: 'Not Found in CSV',
  invalidDate: 'Invalid Date',
};
